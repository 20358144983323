import React, { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { search } from '../../../redux/search/operations';

const InputSearch = memo((props) => {
  // console.log('InputSearch');
  const dispatch = useDispatch();

  // 検索ワードを取得
  const changeKeyword = e => {
    e.preventDefault();
    props.setKeyword(e.target.value);
  };

  // Enter検索(form)後、検索ボタンをフォーカス
  const cursorAfterSubmit = () => {
    // TODOkeywordが未入力の場合、inputSearchが赤になる
    document.getElementById('inputSearch').focus();
  };

  useEffect(() => {
    // 検索ボックス無効化時のclass（hover時の挙動）変更
    const inputSearch = document.getElementById('inputSearch');
    if (props.isInputSearchDisabled) {
      inputSearch.classList.add('disabled');
    } else {
      inputSearch.classList.remove('disabled');
    }
  },[props.isInputSearchDisabled]);

  return (
    <form
      onSubmit={ e => {
        e.preventDefault();     // Enter検索(form)のバグ回避
        cursorAfterSubmit();
        dispatch(search(props.tool, props.area, props.keyword, 1, true));
        props.scrollTop();
      }}
    >
      <input
        className="input-search"
        id="inputSearch"
        type="search"
        value={props.keyword}
        disabled={props.isInputSearchDisabled}
        onChange={ e => changeKeyword(e)}
        name="searchText"
        placeholder="検索ワードを入力"
        aria-label="search"
      />
    </form>
  );
});

export default InputSearch;