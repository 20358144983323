import React, { memo } from 'react';
import { Tooltip } from '@material-ui/core';
// import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import iconAccount from '../../../assets/img/icon-account.png';

const BtnAccount = memo(()=>{
  return(
    <div className="btn-header-icon">
      <Tooltip title="アカウント設定">
        {/* <AccountCircleIcon style={{ fontSize: '55px' }} /> */}
        <img src={iconAccount} alt="アカウント設定" />

        {/* < class="fas fa-user-circle"></i> */}
      </Tooltip>
    </div>
  );
});

export default BtnAccount;