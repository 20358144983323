/***********************
  [search] actions.js
 ***********************/

// typeを定義
export const SAVE_SEARCH_SUPPLEMENT = "SAVE_SEARCH_SUPPLEMENT";
export const ADD_LISTS = "ADD_LISTS";
export const SAVE_SEARCH_CONDITION = "SAVE_SEARCH_CONDITION";
export const CLEAR_SEARCH_CONDITION = "CLEAR_SEARCH_CONDITION";
export const CLEAR_SEARCH_RESULTS = "CLEAR_SEARCH_RESULTS";
export const SEARCH_LIST_OPEN = "SEARCH_LIST_OPEN";
export const ERROR_SEARCH_OPEN = "ERROR_SEARCH_OPEN";
export const NO_RESULTS_OPEN = "NO_RESULTS_OPEN";
export const CLEAR_ALL_SEARCH = "CLEAR_ALL_SEARCH";

// ActionCreatorを作成
// 検索
export const saveForSearchSupplementAction = (searchState) => {
  return {
    type: "SAVE_SEARCH_SUPPLEMENT",
    payload: {
      totalCount: searchState.totalCount,
      nextNo: searchState.nextNo
    }
  };
};

// リスト追加
export const addListsAction = (lists) => {
  return {
    type: "ADD_LISTS",
    payload: {
      lists: lists
    }
  };
};

// 検索条件を保存
export const saveSearchConditionAction = (searchState) => {
  return {
    type: "SAVE_SEARCH_CONDITION",
    payload: {
      tool: searchState.tool,
      area: searchState.area,
      keyword: searchState.keyword
    }
  };
};

// 条件クリア
export const clearSearchConditionAction = () => {
  return{
    type: "CLEAR_SEARCH_CONDITION",
    payload: {
      tool: 'rikunabi',
      area: null,
      keyword: '',
      }
  };
};

// リスト削除
export const clearSearchResultsAction = () => {
  return {
    type: "CLEAR_SEARCH_RESULTS",
    payload: {
      lists : [],
      totalCount: 0,
      nextNo: 1,
      }
  };
};

// リスト（検索結果）画面の表示・非表示
export const searchListOpenAction = (isSearchListOpen) => {
  return {
    type: "SEARCH_LIST_OPEN",
    payload: { isSearchListOpen : isSearchListOpen }
  };
};

// エラー画面の表示・非表示
export const errorSearchOpenAction = (isErrorSearchOpen) => {
  return {
    type: "ERROR_SEARCH_OPEN",
    payload: { isErrorSearchOpen : isErrorSearchOpen }
  };
};

// 検索結果なし画面の表示・非表示
export const noResultsOpenAction = (isNoResultsOpen) => {
  return {
    type: "NO_RESULTS_OPEN",
    payload: { isNoResultsOpen : isNoResultsOpen }
  };
};

// search stateを全て削除（ログアウト）
export const clearAllSearchAction = () => {
  return {
    type: "CLEAR_ALL_SEARCH",
    payload: {
      lists: [],
      tool: 'rikunabi',
      area: null,
      keyword: '',
      totalCount: 0,
      nextNo: 1,
      isSearchListOpen: false,
      isErrorSearchOpen: false
    }
  };
};