import { push } from "connected-react-router";
import { alertLoginFailedOpen } from "./redux/alerts/operations";

export const handleErrorAlert = async (res, dispatch) => {
  // const json = await res.json();

  switch (res.status) {
    case 401: // ログイン失敗
      dispatch(alertLoginFailedOpen());
      dispatch(push("/login"));
      break;
    case 403: // 未承認状態のため強制ログイン遷移
      // alert("認証情報が有効ではありません。ログインし直してください。", json.message);
      alert("認証情報が有効ではありません。ログインし直してください。");
      dispatch(push("/login"));
      break;
    case 500: // サーバーエラー
      // alert("システムエラーが発生しました。", json.message);
      dispatch(push("/err"));
      break;
    default:
      alert("その他のエラー");
      dispatch(push("/err"));
      break;
  }
};

// export const handleOK = async (res) => {
//   const json = await res.json();

//   switch(res.status){
//     case 200:
//     case 201: // 登録成功
//       alert(json.message);
//       break;
//     default:
//       alert('成功（その他）');
//       break;
//   }
// };

// createUserの最後に入れる
// return handleErrorAlert(res, dispatch)
