/*************************
  [users] operations.js
 *************************/

import { push } from 'connected-react-router';
import { handleErrorAlert } from '../../handleHttpStatus';
import * as Actions from './actions';
import { alertNoInputOpen } from '../alerts/operations';
import { clearSearch } from '../search/operations';
// import Cookies from 'js-cookie';


// Actionsを呼び出す関数

// ▼ ログイン
export const signIn = ( tenantCode, userName, password ) => {
  return async (dispatch) => {
    // validation
    if(!tenantCode.match(/^[A-Za-z0-9]*$/)){
      // alert('テナントコードは「半角英数字」で入力してください。')
      // dispatch(alertTenantCodeHalfAlphanumericOpen());
      return false;
    } else {
      if(!userName.match(/^[A-Za-z0-9]*$/)){
        // alert('ユーザーIDは「半角英数字」で入力してください。')
        // dispatch(alertUserIDHalfAlphanumericOpen());
        return false;
      } else {
        if(tenantCode === '' || userName === '' || password === ''){
          // alert('必須項目が未入力です。')
          dispatch(alertNoInputOpen());
          return false;
        }
      }
    }

    const url = process.env.REACT_APP_URL + 'signin';
    const encodeUrl = encodeURI(url);
    const params = {
      method: 'POST',
      body: JSON.stringify({tenantCode: tenantCode, userName: userName, password: password}),
      mode: 'cors',  // （デフォルト）※Cross-Originリクエスト許可（別domainへのアクセス時にセキュリティクリア）
      credentials: 'include', // cookie送受信可
    };

    /**
     *  @function 【signInGetUser】
     *  userIdに対するpasswordが正常か判定し、正常の場合、
     *  ユーザー情報を返し、発行したJWTトークンをcookieに保存する
     *  ------------------------------------
     *  @param  {string} tenantCode - テナントコード
     *  @param  {string} userName   - ユーザー名
     *  @param  {string} password   - ログインパスワード
     *  @return {object} user       - ユーザー情報
     */
    await fetch(encodeUrl, params)
      .then( res => {
        console.log(res.status);
        if(!res.ok){
          console.log(res);
          handleErrorAlert(res, dispatch);
          // throw new Error(`${res.status} ${res.statusText}`);
        } else {
          console.log(res);
          return res.json();
        }
      })
      .then( json => {
        // const token = await signInGetToken.headers.get('Authorization');
        // console.log('JWTトークン：', token);
        const data = json.data;

        const userId =  data.userId;
        const displayName =  data.displayName;
        const email =  data.email;
        const companyId =  data.companyId;
        const roleId =  data.roleId;

        // signInActionを呼び出し、state変更
        dispatch(Actions.signInAction({
          userId: userId,
          userName: userName,
          displayName: displayName,
          email: email,
          companyId: companyId,
          roleId: roleId,
        }));

        dispatch(push('/'))
      })
      // サーバー通信ネットワークエラー
      .catch(err => {
        console.log(err);
        console.log("error.message", err.message);
        // サーバーダウン時にエラー画面遷移
        if(err.message === 'Failed to fetch'){
          dispatch(push('/err'));
        }
        return false;
      })
  };
}


// ▼ ログアウト
export const signOut = () => {
// export const signOut = ( userName ) => {
  return async (dispatch) => {
    const url = process.env.REACT_APP_URL + 'signout';
    // const signOutRemoveTokenUrl = process.env.REACT_APP_URL + 'signout/' + userName;
    const encodeUrl = encodeURI(url);
    const params = {
      // method: 'POST',
      method: 'GET',
      // body: JSON.stringify({userName: userName}),
      mode: 'cors',
      credentials: 'include'
    };

    /**
     *  @function 【signOutRemoveToken】
     *  cookieのJWTトークンを削除
     *  ------------------------------------
     *  @param  {string} userName - ユーザー名
     *  @return res
     */
    // const signOutRemoveToken = await fetch(encodeSignOutRemoveTokenUrl, paramsSignOutRemoveToken)
    await fetch(encodeUrl, params)
      .then( res => {
        if(!res.ok){
          console.log(res);
          handleErrorAlert(res, dispatch);
          // throw new Error(`${res.status} ${res.statusText}`);
        }
        console.log(res);

        // console.log(signOutRemoveToken);

        // cookie「'jwt」を削除
        // document.cookie = "jwt=; max-age=0";  // ※有効期限を0にする
        // Cookies.remove('jwt');

        // 各stateを初期化
        dispatch(Actions.signOutAction());
        dispatch(clearSearch());

        dispatch(push('/login'));

        // window.location.reload(); // 最後にリロードしてcookie削除
      })
      .catch(error => {
        console.log("getUser error.message", error.message);
        // サーバーダウン時のエラー画面遷移
        dispatch(push('/err'));
        return false;
      })
  };
 };

// ▼ お気に入り画面表示
export const reviewList = () => {
  return async (dispatch) => {
    dispatch(push('/review'));
  };
};

// ▼ 管理者画面表示
export const forAdministrator = () => {
  return async (dispatch) => {
    dispatch(push('/account'));
  };
};

// ▼ 認証確認
export const listenAuthState = (isSignedIn) => {
  return async (dispatch) => {
    /**
     *  @function 【monitorAuthState】
     *  JWTトークンで認証状態を監視
     *  ------------------------------------
     *  @return (認証あり) {object} user - ユーザー情報
     *  @return (認証なし)          null
     */
    // const monitorAuthState = await fetch(encodeUrl, params)
    //   .then( res => {
    //     if(!res.ok){
    //       throw new Error(`${res.status} ${res.statusText}`);
    //     }
    //     return res.json();
    //   })
    //   .catch(error => {
    //     console.log("getUser error.message", error.message);
    //   })

    // if(monitorAuthState){
    //   // signInActionを呼び出し、state変更
    //   dispatch(Actions.signInAction({
    //     isSignedIn: true,
    //     userId: userId,
    //     userName: userName,
    //     displayName: displayName,
    //     email: email,
    //     companyId: companyId,
    //     roleId: roleId,
    //   }));
    // } else {
    (isSignedIn) ? (dispatch(push('/'))) : (dispatch(push('/login')))
      // dispatch(push('/login'));
    // }
  };
};

// ▼ 初期化（users state）
export const clearUsers = () => {
  return async (dispatch) => {
    dispatch(Actions.signOutAction());
  }
};