import React from 'react';
// import { useDispatch } from 'react-redux';
// import { push } from 'connected-react-router';
// import { ButtonDefault } from '../components';
import ErrorImage404 from '../../assets/img/404err.png';

const Page404 = () => {
  // const dispatch = useDispatch();

  return(
    <div className="section-container">
      <div className="section-contents">
        <h1><img src={ErrorImage404} alt="404 Not Found" style={{ width: '500px' }}  /></h1>
        <br />
        <h2>お探しのページは見つかりませんでした。</h2>
        <p>お探しのページは、移動または削除された可能性があります。</p>
        {/* <div className="center">
          <ButtonDefault
            label={"戻る"}
            disabled={false}
            onClick={() => dispatch(push('/'))}
          />
        </div> */}
      </div>
    </div>
  );
};

export default Page404;