/************************
  [search] reducers.js
 ************************/

import * as Actions from './actions';
import initialState from '../store/initialState';

// state状態変化させる関数を作成
export const SearchReducer = (state = initialState.search, action) => {
  switch (action.type){
    case Actions.SAVE_SEARCH_SUPPLEMENT:
    case Actions.CLEAR_SEARCH_CONDITION:
    case Actions.SAVE_SEARCH_CONDITION:
    case Actions.CLEAR_SEARCH_RESULTS:
    case Actions.SEARCH_LIST_OPEN:
    case Actions.ERROR_SEARCH_OPEN:
    case Actions.NO_RESULTS_OPEN:
      return {
        ...state,
        ...action.payload
      };
    case Actions.ADD_LISTS:
      return {
        ...state,
        lists: [...state.lists, ...action.payload.lists]
      };
    case Actions.CLEAR_ALL_SEARCH:
      return {
        ...action.payload
      };
    default:
      return state;
  }
};