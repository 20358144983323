import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getIsSignedIn } from "./redux/users/selectors";
import { listenAuthState } from "./redux/users/operations";
// import { Redirect } from "react-router";
// import { Page404 } from "./views/pages";
// import path404Exclude from "./data/path404Exclude";
// import { useLocation } from "react-router";

const Auth = ({children}) => {
  const dispatch = useDispatch();
  const selector = useSelector(state => state);
  const isSignedIn = getIsSignedIn(selector);
  // let location = useLocation();

  useEffect(() => {
    if(!isSignedIn){
      dispatch(listenAuthState(isSignedIn));
    }
  }, [dispatch, isSignedIn]);

  if(!isSignedIn){
    console.log('認証なし（ログアウト）');
    return <></>
    // return <Redirect to = {{
    //   pathname: '/login'
    //   // state: {from: location}
    // }}/>
  } else {
    // 404 Not Found
    // if(!path404Exclude.includes(location.pathname)){
    //   return <Page404 />
    // } else {
      console.log('認証あり（ログイン）');
      // console.log(routerPathName);
      return children;
    // }
  }
};

export default Auth;