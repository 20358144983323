import React, { memo, useState, useEffect } from 'react';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { animateScroll as scroll } from "react-scroll";

const BtnReturnTop = memo(() =>{
  const [isBtnReturnTopActive, setIsBtnReturnTopActive] = useState(false);

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollWindow);
    return () => window.removeEventListener('scroll', scrollWindow);
  }, []);

  const scrollWindow = () => {
    const top = 100;  // ボタンを表示させる位置
    let scroll = 0;
    scroll = window.scrollY;
    if (top <= scroll) {
      setIsBtnReturnTopActive(true);
    } else {
      setIsBtnReturnTopActive(false);
    }
  };

  return (
      <div className="scroll-top-wrapper">
          <button
            id="btn-scroll-top"
            className="btn-scroll btn-scroll-top"
            onClick={ e => {
              e.preventDefault();
              scrollToTop();
            }}
            style={{display: isBtnReturnTopActive ? 'flex' : 'none'}}>
            <ExpandLessIcon style={{ fontSize:"50px"}} />
          </button>
     </div>

  );
});

export default BtnReturnTop;