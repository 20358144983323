import React, { memo } from 'react';
import {List} from '../';

const Lists = memo((props) => {
  return (
    <ul>
      {props.lists.map((list, index) => {
        return (
          <List
            list={list}
            key={index.toString()}
          />
        );
      })}
    </ul>
  );
});

export default Lists;
