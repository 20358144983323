import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Lists, TotalCount } from '.';
import { getLists, getTotalCount } from '../../redux/search/selectors';

const SearchList = memo(() => {
  // console.log('SearchList');
  const selector = useSelector(state => state);
  const lists = getLists(selector);
  const totalCount = getTotalCount(selector);

  return (
    <>
      {/* 総件数 */}
      <TotalCount
        totalCount={totalCount}
      />

      <div className="list-wrapper">
        <Lists
          lists={lists}
        />
      </div>
    </>
  );
});

export default SearchList;