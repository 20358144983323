import React, { memo } from 'react';
import imgSVGPath from '../../../data/imgSVGPath';
// import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

const BtnLinkDetail = memo((props) => {
  return(
    <a href={props.detailUrl} target="#" className="btn btn-link-detail">
       詳細情報 <svg className="icon-detail" viewBox="0 0 512 512">
        <path d={imgSVGPath.iconDetailSVGPath}></path>
        </svg>
      {/* <DoubleArrowIcon style={{ fontSize: '15px' }} /> 詳細情報 */}
    </a>
  );
});

export default BtnLinkDetail