/***********************
  [users] actions.js
 ***********************/

// typeを定義
export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";

// ActionCreatorを作成
export const signInAction = (userState) => {
  return {
    type: "SIGN_IN",
    payload: {
      isSignedIn: true,
      userId: userState.userId,
      userName: userState.userName,
      displayName: userState.displayName,
      email: userState.email,
      companyId: userState.companyId,
      roleId: userState.roleId,
    }
  };
};

export const signOutAction = () => {
  return{
    type: "SIGN_OUT",
    payload: {
      isSignedIn: false,
      userId: '',
      userName: '',
      displayName: '',
      email: '',
      companyId: '',
      roleId: '',
    }
  };
};