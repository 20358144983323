import React from 'react';
import './assets/styles/styles.css';
import Router from './Router';

const App = () => {
  return(
    <>
      <Router />
    </>
  );
}

export default App;