import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIsAlertBarOpen } from '../../redux/alerts/selectors';
import { signIn } from '../../redux/users/operations';
import { ButtonDefault, AlertBar, InputBoxDefault } from '../components';
import iconLogin from '../../assets/img/icon-login-key2.png';

const Login = () => {
  const dispatch = useDispatch();
  const selector = useSelector(state => state);
  const isAlertBarOpen = getIsAlertBarOpen(selector);

  const [tenantCode, setTenantCode] = useState(''),
        [userName, setUserName] = useState(''),
        [password, setPassword] = useState(''),
        [isLoginDisabled, setLoginDisabled] = useState(false);

  // const inputTenantCode = useCallback(e => {
  //   e.preventDefault();
  //   if(getIsAlertBarOpen){dispatch(alertClose());}
  //   setTenantCode(e.target.value);
  // }, [dispatch]);

  // const inputUserName = useCallback(e => {
  //   e.preventDefault();
  //   if(getIsAlertBarOpen){dispatch(alertClose());}
  //   setUserName(e.target.value);
  // }, [dispatch]);

  // const inputPassword = useCallback(e => {
  //   e.preventDefault();
  //   if(getIsAlertBarOpen){dispatch(alertClose());}
  //   setPassword(e.target.value);
  // }, [dispatch]);

  useEffect(() => {
    console.log(selector.users);
  }, [selector.users]);

  // ログインボタンの非活性切り替え
  useEffect(() => {
    if (tenantCode === '' || userName === '' || password === '') {
      setLoginDisabled(true);
    } else {
      if(!tenantCode.match(/^[A-Za-z0-9]*$/) || !userName.match(/^[A-Za-z0-9]*$/)){
        setLoginDisabled(true);
      } else {
      setLoginDisabled(false);
      }
    }
  },[tenantCode, userName, password]);

  return (
    <div className="login-container">
      {/* エラーアラート */}
      <div className="login-form">
        <div className="login-form-header">
          <img src={iconLogin} alt="login" />
          <h2>ログイン</h2>
        </div>

        { isAlertBarOpen ? (<AlertBar />) : (<></>)}

        <div className="login-form-input">
          {/* テナントコード */}
          <InputBoxDefault
            label={'テナントコード'}
            value={tenantCode}
            type={'text'}
            labelId={'labelTenant'}
            inputId={'inputTenant'}
            alertId={'alertTenant'}
            setState={setTenantCode}
            // onChange={e => inputTenantCode(e)}
            onSubmit={() => {dispatch(signIn(tenantCode, userName, password))}}
          />
          {/* ユーザーID */}
          <InputBoxDefault
            label={'ユーザーID'}
            value={userName}
            type={'text'}
            labelId={'labelUserName'}
            inputId={'inputUserName'}
            alertId={'alertUserName'}
            setState={setUserName}
            // onChange={e => inputUserName(e)}
            onSubmit={() => {dispatch(signIn(tenantCode, userName, password))}}
          />
          {/* パスワード */}
          <InputBoxDefault
            label={'パスワード'}
            value={password}
            type={'password'}
            labelId={'labelPassword'}
            inputId={'inputPassword'}
            alertId={'alertPassword'}
            setState={setPassword}
            // onChange={e => inputPassword(e)}
            onSubmit={() => {dispatch(signIn(tenantCode, userName, password))}}
          />

          {/* ▼ material-ui ▼ */}
          {/* テナントコード */}
          {/* <TextInput
            fullWidth={true} label={'テナントコード'} multiline={false}
            required={true} rows={1} value={tenantCode}
            // autoFocus={true}
            type={'text'}
            // inputProps={{pattern: "^[a-zA-Z0-9_]+$"}}
            helperText={'※半角英数字で入力してください。'}
            onChange={e => inputTenantCode(e)} onSubmit={() => {dispatch(signIn(tenantCode, userName, password))}}
          /> */}
          {/* ユーザーID */}
          {/* <TextInput
            fullWidth={true} label={'ユーザーID'} multiline={false}
            required={true} rows={1} value={userName}
            // autoFocus={false}
            type={'text'}
            // inputProps={{pattern: "^[a-zA-Z0-9_]+$"}}
            helperText={'※半角英数字で入力してください。'}
            onChange={e => inputUserName(e)} onSubmit={() => {dispatch(signIn(tenantCode, userName, password))}}
          /> */}
          {/* パスワード */}
          {/* <TextInput
            fullWidth={true} label={'パスワード'} multiline={false}
            required={true} rows={1} value={password}
            // autoFocus={false}
            type={'password'} helperText={''}
            onChange={e => inputPassword(e)} onSubmit={() => {dispatch(signIn(tenantCode, userName, password))}}
          /> */}

        </div>
        {/* ログインbutton */}
        <div className="center">
          <ButtonDefault
            label={"ログイン"}
            disabled={isLoginDisabled}
            onClick={() => {
              dispatch(signIn(tenantCode, userName, password))
              console.log('ログインclicked')
            }}
          />
        </div>
        {/* パスワードリセット */}
        <div className="center reset-password">
          <p><a href="mailTo:mikke@gmail.com">ログインID・パスワードをお忘れの方（再設定）</a></p>
        </div>
      </div>
    </div>
  );
};

export default Login;