import React from 'react';
import { getIsAlertBarOpen } from '../../../redux/alerts/selectors';
import { alertClose } from '../../../redux/alerts/operations';
import { useDispatch } from 'react-redux';

const InputBoxDefault = (props) => {
  const dispatch = useDispatch();

  const targetLabel = document.getElementById(props.labelId);
  const targetInput = document.getElementById(props.inputId);
  const targetAlert = document.getElementById(props.alertId);

  const onFocusInput = () =>{
    if(props.inputId === 'inputTenant' || props.inputId === 'inputUserName'){
      if(props.value.match(/^[A-Za-z0-9]*$/)){
        targetLabel.classList.add('input-label_active');
        targetLabel.classList.remove('input-label_visited');
        targetInput.classList.remove('input_visited_alert');
        targetAlert.classList.remove('input-alertText_visited_alert');
      }
    } else {  // props.inputId === 'inputPassword'
      targetLabel.classList.add('input-label_active');
      targetLabel.classList.remove('input-label_visited');
      targetInput.classList.remove('input_visited_alert');
      targetAlert.classList.remove('input-alertText_visited_alert');
    }
  };

  const inputValue = e => {
    e.preventDefault();
    if(getIsAlertBarOpen){dispatch(alertClose());}
    if(props.inputId === 'inputTenant' || props.inputId === 'inputUserName'){
      if(!e.target.value.match(/^[A-Za-z0-9]*$/)){
        targetInput.classList.add('input_visited_alert');
        targetAlert.classList.add('input-alertText_visited_alert');
        // targetLabel.classList.add('input-label_alert'); // アラート時ラベル：赤
        targetLabel.classList.add('input-label_visited'); // アラート時ラベル：グレー
      } else {
        targetInput.classList.remove('input_visited_alert');
        targetAlert.classList.remove('input-alertText_visited_alert');
        // targetLabel.classList.remove('input-label_alert');
        targetLabel.classList.remove('input-label_visited');
      }
    }
    props.setState(e.target.value);
  };

  const onBlurInput = () => {
    if(props.value === ''){
      targetLabel.classList.remove('input-label_active');
    } else {
      targetLabel.classList.add('input-label_visited');
    }
  };

  return(
    <form
      autoComplete="off"
      onSubmit={ e => { // Enterで実行
      e.preventDefault();
      onBlurInput();
      props.onSubmit();}}
    >
      <div className="input-wrapper">
        <label
          className='input-label'
          id={props.labelId}
          htmlFor={props.inputId}
        >{props.label}</label>
        <input
          className='input'
          id={props.inputId}
          type={props.type}
          value={props.value}
          // required
          spellCheck='false'  // 半角入力時の赤い波線消す
          onFocus={() => onFocusInput()}
          onBlur={() => onBlurInput()}
          onChange={e => {
            e.preventDefault();
            // props.onChange(e);
            inputValue(e);
          }}
        />
        <p
          className="input-alertText"
          id={props.alertId}
        >※半角英数字で入力してください。</p>
      </div>
    </form>
  );
};

export default InputBoxDefault;