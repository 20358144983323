/************************
  [loading] reducers.js
 ************************/

import * as Actions from './actions';
import initialState from '../store/initialState';

// state状態変化させる関数を作成
export const LoadingReducer = (state = initialState.loading, action) => {
  switch (action.type){
    case Actions.START_FIRST_LOADING:
      return {
        ...state,
        ...action.payload
      };

    case Actions.START_SHOWMORE_LOADING:
      return {
        ...state,
        ...action.payload
      };

    case Actions.STOP_LOADING:
      return {
        ...action.payload
      };

    default:
      return state;
  }
};