import React from 'react';
import { useSelector } from 'react-redux';
import { BtnShowMore, Loading } from '.';
import { getIsShowMoreLoadingStart } from '../../redux/loading/selectors';

const ShowMore = (props) => {
  // console.log('ShowMore');
  const selector = useSelector(state => state);
  const isShowMoreLoadingStart = getIsShowMoreLoadingStart(selector);

  return (
      <div className="show-more-wrapper">
        { isShowMoreLoadingStart ? (
          <div className="more-loading">
            <Loading />
          </div>
        ) : (
          <BtnShowMore />
        )}
      </div>
  );
};

export default ShowMore;