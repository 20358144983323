import React , { memo } from 'react';
import headerLogo from '../../../assets/img/logo.png';
import { animateScroll as scroll } from "react-scroll";

const Logo = memo(() => {

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  return (
    <div className="header-logo">
      <img src={headerLogo} alt="しごとみっけ"
        onClick={ e => {
          e.preventDefault();
          scrollToTop();
        }}
      />
      {/* <span>しごとみっけ</span> */}
    </div>
  );
});

export default Logo;
