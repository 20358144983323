import React from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  textFiled: {
    width: 450,
    marginTop: 8,
  }
});

const TextInput = (props) => {
  const classes = useStyles();

  return(
    <form
      autoComplete="off"
      onSubmit={ e => { // Enterで実行
      e.preventDefault();
      props.onSubmit();
    }}>
      <TextField
        className={classes.textFiled}
        variant="outlined"
        fullWidth={props.fullWidth}   // true(画面全体)/false(適正な幅)
        label={props.label}
        margin="dense"
        multiline={props.multiline}   // 複数行(boolean)
        // required={props.required}     // 入力必須(boolean)
        rows={props.rows}             // multilineがtrueの際、行数指定(※multilineがfalseの際もrows=「1」を指定)
        value={props.value}
        type={props.type}             // htmlのinputタグと同じtype属性
        helperText={props.helperText}
        // autoFocus={props.autoFocus}
        onChange={e => {
          e.preventDefault();
          props.onChange(e);
        }}
      />
    </form>
  );
};

export default TextInput;