import React from 'react';
import ReactDOM from 'react-dom';
import './assets/styles/index.css';
import App from './App';
import { Provider } from 'react-redux';
import { generateStore } from './redux/store/store';
import { ConnectedRouter } from 'connected-react-router';
import * as History from 'history';
// import * as serviceWorker from './serviceWorker';

// history作成
const history = History.createBrowserHistory();

// store作成
export const store = generateStore(history);

if (process.env.NODE_ENV === "production"){
  console.log = () => {};
  console.debug = () => {};
  console.info = () => {};
  console.warn = () => {};
  console.error = () => {};
}

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// ※serviceWorker使う時のみ
// serviceWorker.unregister();
