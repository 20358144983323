/*************************
  [search] operations.js
 *************************/

import { push } from 'connected-react-router';
import { handleErrorAlert } from '../../handleHttpStatus';
// import { alertOpenAction } from '../alerts/actions';
import { clearUsers } from '../users/operations'
import { startFirstLoading, startShowMoreLoading, stopLoading } from '../loading/operations';
import * as Actions from './actions';

// Actionsを呼び出す関数

// ▼ 検索
export const search = ( tool, area, keyword, nextNo, isFirstRequest ) => {
  return async (dispatch) => {
    // validation
    // if(keyword === ''){
    //   alert('必須項目が未入力です。')
    //   // dispatch(alertNoInputOpen());
    //   return false;
    // }

    // ローディング start
    if(isFirstRequest){
      dispatch(startFirstLoading());
      // リスト、totalCount、nextNoを削除
      dispatch(Actions.clearSearchResultsAction());
    } else {
      dispatch(startShowMoreLoading());
    }

    // エラー画面閉じる
    dispatch(Actions.errorSearchOpenAction(false));
    dispatch(Actions.noResultsOpenAction(false));

    let url = process.env.REACT_APP_URL + tool + '/list?keyword=' + keyword + '&no=' + nextNo;
    if (area !== 'nothing' && area !== null) { url += '&area=' + area; }
    const encodeUrl = encodeURI(url);
    const params = {
      mode: 'cors',  // （デフォルト）※Cross-Originリクエスト許可（別domainへのアクセス時にセキュリティクリア）
      credentials: 'include', // cookie送受信可
    };

    /**
     *  @function【getSearchData】
     *  検索ワードからAPIを叩き検索結果を取得
     *  ------------------------------------
     *  @param  {number}  nextNo         - 次のリクエストナンバー
     *  @param  {boolean} isFirstRequest - true（初回検索）/ false（show more検索）
     *  @return {object}  data
     */
    await fetch(encodeUrl, params)
      .then(async (res) => {
        console.log('res:',res);
        // console.error('error', res);
        const json = await res.json();
        console.log('json:', json);

        if (!res.ok) { // HTTPステータスが200-299以外
          //  setHttpStatus(res.status);
          //  setErrorMessage(json.message);
           console.log(res.status, json.message);
           handleErrorAlert(res, dispatch);
           // 検索結果を初期化
           dispatch(clearSearch());
           // ログイン情報を初期化
           dispatch(clearUsers());
           // ローディング情報を初期化
           dispatch(stopLoading());
          // 検索条件保存
          // dispatch(Actions.saveSearchConditionAction({
          //   tool: tool,
          //   area: area,
          //   keyword: keyword,
          // }))
          // 検索結果画面を閉じる
          // dispatch(Actions.searchListOpenAction(false));
          // エラー画面を開く
          // dispatch(Actions.errorSearchOpenAction(true));
          // ローディング stop (エラーアラートの前に止める)
          // dispatch(stopLoading());
          // エラーアラートを開く
          // dispatch(alertOpenAction());
          // return null;
          return false;
        } else {
          return json;
        }
      })
      .then( json => {
        const lists = json.data;
        const totalCount =  json.totalCount;
        const nextNo =  json.nextNo;

        dispatch(Actions.addListsAction(lists));
        // 検索条件保存
        dispatch(Actions.saveSearchConditionAction({
          tool: tool,
          area: area,
          keyword: keyword,
        }))
        dispatch(Actions.saveForSearchSupplementAction({
          totalCount: totalCount,
          nextNo: nextNo
        }));

        if(totalCount === 0 && nextNo === 0){
          dispatch(Actions.noResultsOpenAction(true));
        }

        // 検索結果画面を開く
        dispatch(Actions.searchListOpenAction(true));
        // ローディング stop
        dispatch(stopLoading());
      })
      // サーバー通信ネットワークエラー
      .catch(err => {
        console.log(err);
        console.log("error.message", err.message);
        dispatch(stopLoading());
        // サーバーダウン時にエラー画面遷移
        if(err.message === 'Failed to fetch'){
          dispatch(push('/err'));
        }
        return false;
      })
  };
}

// ▼ 初期化（search state）
export const clearSearch = () => {
  return async (dispatch) => {
    dispatch(Actions.clearAllSearchAction());
  }
};