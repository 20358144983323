const search_tools = [
  { display: "リクナビ", value: "rikunabi" },
  { display: "doda", value: "doda" },
  { display: "マイナビ", value: "mynavi" },
  // { display: "エン転職", value: "en-japan" },
];

const areas = [
  { display: "北海道", value: 'hokkaido' },
  { display: "青森", value: 'aomori' },
  { display: "岩手", value: 'iwate' },
  { display: "宮城", value: 'miyagi' },
  { display: "秋田", value: 'akita' },
  { display: "山形", value: 'yamagata' },
  { display: "福島", value: 'fukushima' },
  { display: "東京", value: 'tokyo' },
  { display: "神奈川", value: 'kanagawa' },
  { display: "埼玉", value: 'saitama' },
  { display: "千葉", value: 'chiba' },
  { display: "茨城", value: 'ibaraki' },
  { display: "群馬", value: 'gunma' },
  { display: "栃木", value: 'tochigi' },
  { display: "山梨", value: 'yamanashi' },
  { display: "新潟", value: 'niigata' },
  { display: "富山", value: 'toyama' },
  { display: "石川", value: 'ishikawa' },
  { display: "福井", value: 'fukui' },
  { display: "長野", value: 'nagano' },
  { display: "愛知", value: 'aichi' },
  { display: "静岡", value: 'shizuoka' },
  { display: "岐阜", value: 'gifu' },
  { display: "三重", value: 'mie' },
  { display: "大阪", value: 'osaka' },
  { display: "兵庫", value: 'hyogo' },
  { display: "京都", value: 'kyoto' },
  { display: "滋賀", value: 'shiga' },
  { display: "奈良", value: 'nara' },
  { display: "和歌山", value: 'wakayama' },
  { display: "鳥取", value: 'tottori' },
  { display: "島根", value: 'shimane' },
  { display: "岡山", value: 'okayama' },
  { display: "広島", value: 'hiroshima' },
  { display: "山口", value: 'yamaguchi' },
  { display: "徳島", value: 'tokushima' },
  { display: "香川", value: 'kagawa' },
  { display: "愛媛", value: 'ehime' },
  { display: "高知", value: 'kochi' },
  { display: "福岡", value: 'fukuoka' },
  { display: "佐賀", value: 'saga' },
  { display: "長崎", value: 'nagasaki' },
  { display: "熊本", value: 'kumamoto' },
  { display: "大分", value: 'oita' },
  { display: "宮崎", value: 'miyazaki' },
  { display: "鹿児島", value: 'kagoshima' },
  { display: "沖縄", value: 'okinawa' },
];

const noArea = { display: "-- 指定なし --", value: 'nothing' };

const dataDropDown = search_tools.map((d, index) => {
  return {
    toolsId: index,
    display: d.display,
    value: d.value,
    areas: (d.value === 'mynavi') ? [
      ...areas
    ] : [
      //「指定なし」を追加
      noArea, ...areas
    ],
    supplementary : (d.value === 'mynavi') ? '※「マイナビ」は勤務地指定必須' : ''
  };
});

export default dataDropDown;