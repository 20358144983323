import React, { memo } from 'react';

const TotalCount = memo((props) => {
  return (
    <div className="total-count">
      <p>検索結果：<span>{props.totalCount}</span> 件</p>
    </div>
  );
});

export default TotalCount;