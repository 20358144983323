/*************************
  [loading] selections.js
 *************************/

import { createSelector } from "reselect";

const loadingSelector = state => state.loading;

// stateを取得する関数
export const getIsFirstLoadingStart = createSelector(
  [loadingSelector],
  state => state.isFirstLoadingStart
);

export const getIsShowMoreLoadingStart = createSelector(
  [loadingSelector],
  state => state.isShowMoreLoadingStart
);