/********************
  initialState.js
 ********************/

const initialState = {
  users: {
    isSignedIn: false,
    userId: '',
    userName: '',
    displayName: '',
    email: '',
    companyId: '',
    roleId: '',
  },
  alerts: {
    isAlertBarOpen: false,
    // alertSeverity: '',
    alertTitle: '',
    alertText: '',
  },
  search: {
    lists: [],
    tool: 'rikunabi',
    area: null,
    keyword: '',
    totalCount: 0,
    nextNo: 1,
    isSearchListOpen: false,
    isErrorSearchOpen: false,
    isNoResultsOpen: false
  },
  loading: {
    isFirstLoadingStart: false,
    isShowMoreLoadingStart: false
  }
};

export default initialState;



