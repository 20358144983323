/*************************
  [alerts] operations.js
 *************************/

import * as Actions from './actions';

// Actionsを呼び出す関数

// ▼ ログイン失敗アラート表示
export const alertLoginFailedOpen = () => {
  return async (dispatch) => {
    // alertOpenActionを呼び出し、state変更
    dispatch(Actions.alertOpenAction({
      // alertSeverity: 'error',
      alertTitle: 'ログインに失敗しました。',
      alertText: '『テナントコード / ユーザーID / パスワード』をご確認の上、再度ログインしてください。',
    }));
  };
}

// ▼ 「必須項目未入力」注意アラート表示
export const alertNoInputOpen = () => {
  return async (dispatch) => {
    // alertOpenActionを呼び出し、state変更
    dispatch(Actions.alertOpenAction({
      // alertSeverity: 'warning',
      alertTitle: '必須項目が未入力です。',
      alertText: '必須項目を入力し、再度ログインしてください。',
    }));
  };
}

// ▼ 「半角英数字」注意アラート表示
export const alertTenantCodeHalfAlphanumericOpen = () => {
  return async (dispatch) => {
    // alertOpenActionを呼び出し、state変更
    dispatch(Actions.alertOpenAction({
      // alertSeverity: 'warning',
      alertTitle: '「テナントコード」は半角英数字で入力してください。',
      alertText: 'テナントコードをお確かめの上、再度ログインしてください。',
    }));
  };
}

export const alertUserIDHalfAlphanumericOpen = () => {
  return async (dispatch) => {
    // alertOpenActionを呼び出し、state変更
    dispatch(Actions.alertOpenAction({
      // alertSeverity: 'warning',
      alertTitle: '「ユーザーID」は半角英数字で入力してください。',
      alertText: 'ユーザーIDをお確かめの上、再度ログインしてください。',
    }));
  };
}

// ▼ アラート閉じる
export const alertClose = () => {
  return async (dispatch) => {
    // alertCloseActionを呼び出し、state変更
    dispatch(Actions.alertCloseAction());
  };
}