/*************************
  [users] selections.js
 *************************/

import { createSelector } from "reselect";

const usersSelector = state => state.users;

// stateを取得する関数
export const getIsSignedIn = createSelector(
  [usersSelector],
  state => state.isSignedIn
);

export const getUserId = createSelector(
  [usersSelector],
  state => state.userId
);

export const getUserName = createSelector(
  [usersSelector],
  state => state.userName
);