import React, { memo } from 'react';
import { CSVLink } from "react-csv";
import { Tooltip } from '@material-ui/core';
import iconCSV from '../../../assets/img/icon-csv.png';
import { useSelector } from 'react-redux';
import { getArea, getKeyword, getLists, getTool } from '../../../redux/search/selectors';
// import encoding from 'encoding-japanese';

const BtnCSV = memo((props) => {
  const selector = useSelector(state => state);
  const lists = getLists(selector);
  const tool = getTool(selector);
  const area = getArea(selector);
  const keyword = getKeyword(selector);

  let now = new Date();
  let date = `${now.getFullYear()}${now.getMonth()}${now.getDate()}_${now.getHours()}${now.getMinutes()}${now.getSeconds()}`;
  let areaCondition = (area !== 'nothing' && area !== null) ? (area + "_") : "";
  let searchCondition = tool + "_" + areaCondition + keyword;

  // const encodingLists = encoding.convert(props.lists, {
  //   to: 'SJIS',
  //   from: 'AUTO',
  // });

  return (
    <CSVLink
      className="btn-csv"
      data={lists}
      // data={encodingLists}
      separator={","}
      filename={"job-search_" + searchCondition + "_" + date + ".csv"}
      // filename={"job-search_" + keyword + "_" + date + ".csv"}
      // 「job-search_keyword_ YYYYMMDD_hhmmss.csv」
      enclosingCharacter={`"`}
    >
      <Tooltip title="CSV出力">
        {/* <i class="fas fa-file-csv"></i> */}
        <img src={iconCSV} alt="CSV出力" />
      </Tooltip>
    </CSVLink>
  );
});

export default BtnCSV;