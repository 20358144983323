import React, { memo } from 'react';
// import ReactLoading from 'react-loading';

const Loading = memo(() => {
  return (
    <div className="loading">
      <i className="fas fa-spinner fa-4x fa-spin"></i>
    </div>
    // <ReactLoading type="spinningBubbles" color={'#dbdada'} />
    // <ReactLoading type="spin" color={'#dbdada'} />
    // <ReactLoading type="spokes" color={'#dbdada'} />
    // <ReactLoading type="bars" color={'#dbdada'} />
  );
});

export default Loading;