import React, { memo, useMemo, useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dataDropDown from '../../data/dataDropDown';
import iconReview from '../../assets/img/icon-favorite.png';
import iconAccount from '../../assets/img/icon-account.png';
import iconLogOut from '../../assets/img/icon-logout.png';
import { BtnHeaderIcon, BtnCSV, BtnSearch, DropDown, InputSearch, Logo } from ".";
import { signOut, forAdministrator, reviewList } from '../../redux/users/operations';
import { getLists } from '../../redux/search/selectors';
import { getIsFirstLoadingStart, getIsShowMoreLoadingStart } from '../../redux/loading/selectors';

const Header = memo((props) => {
  const dispatch = useDispatch();
  const selector = useSelector(state => state);
  const lists = getLists(selector);
  const isFirstLoadingStart = getIsFirstLoadingStart(selector);
  const isShowMoreLoadingStart = getIsShowMoreLoadingStart(selector);

  const [isDropDownDisabled, changeDropDownDisabled] = useState(false),       // ドロップダウンの無効化管理
        [isInputSearchDisabled, changeInputSearchDisabled] = useState(false), // 検索ボックスの無効化管理
        [isBtnSearchDisabled, changeBtnSearchDisabled] = useState(true);      // 検索ボタンの無効化管理

  const dataDropDownList = useMemo(() => dataDropDown, []);

  const [tool, setTool] = useState('rikunabi'),
        [area, setArea] = useState('nothing'),
        [keyword, setKeyword] = useState('');

  // DropDown（検索ツール）の「tools」一覧をStateで設定
  const [toolOptions] = useState(
    dataDropDownList.map(d => {
      return {
        id: d.toolsId,
        display: d.display,
        value: d.value
      };
    })
  );

  // DropDown（検索ツール）で選択中の検索ツールをStateで管理
  const [selectedToolValue, setSelectedToolValue] = useState(
    dataDropDownList[0].value
  );

  // 選択中の検索ツールに該当する「areas」一覧をRefで管理
  const areaOptionsRef = useRef(
    dataDropDownList.filter(
      d => d.value === selectedToolValue
    )[0].areas.map( (d, index) => {
      return {
        id: index,
        display: d.display,
        value: d.value
      };
    })
  );

  // DropDown（勤務地）で選択中の勤務地をStateで管理
  const [selectedAreaValue, setSelectedAreaValue] = useState(
    dataDropDownList[0].areas[0].value
  );

  // DropDownの補足説明をStateで管理
  const [selectedToolSupplementary, setSelectedToolSupplementary] = useState('');

  // DropDown（検索ツール）を変更時の処理
  const onCmbToolsChangeHandler = (toolVal) => { // useCallback最適化意味なし
    // 選択中の検索ツールの値ををStateに指定
    setSelectedToolValue(toolVal);
    // 選択中の検索ツールに該当するdataDropDownList
    const selectedToolDataDropDownList = dataDropDownList.filter(
      d => d.value === toolVal
    )[0];
    // 選択中の検索ツールに該当する「areas」一覧
    const selectedToolAreas = selectedToolDataDropDownList.areas;
    // 選択中の検索ツールに該当する先頭の勤務地をStateに指定
    setSelectedAreaValue(selectedToolAreas[0].value);
    setArea(selectedToolAreas[0].value);
    // 選択中の検索ツールに該当する「areas」をRefに指定
    areaOptionsRef.current = selectedToolAreas.map((d, index) => {
      return {
        AreasId: index,
        display: d.display,
        value: d.value
      };
    });

    // 選択中の検索ツールに該当する「supplementary」
    setSelectedToolSupplementary(selectedToolDataDropDownList.supplementary);
  };

  const scrollTop = () =>{
    window.scrollTo({top: 0, behavior: 'auto'});
  };

  // 下スクロールで隠し、上スクロールで現れるHeader設定
  useEffect(() => {
    const header = document.getElementById('top-header'),
          height = 70;  // Headerを隠す高さ(px)
    let offset = 0,
        lastPosition = 0,
        ticking = false;

    const onScroll = (lastPosition) => {
      if (lastPosition > height){
        if (lastPosition > offset){
          header.classList.add('header-animation');
        } else {
          header.classList.remove('header-animation');
        }
        offset = lastPosition;
      }
    };

    return (
      window.addEventListener('scroll', e => {
        e.preventDefault();
        lastPosition = window.scrollY;
        if (!ticking) {
          window.requestAnimationFrame(() => {
            onScroll(lastPosition);
            ticking = false;
          });
          ticking = true;
        }
      })
    );
  },[]);

  // 「Home」キーを押したときに、headerが出るように制御
  const escFunction = useCallback( e => {
    const header = document.getElementById('top-header');
    if (e.keyCode === 36) { // 「Home」のキーコード
      // console.log('home');
      header.classList.remove('header-animation');
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction);
  }, [escFunction]);

  // ドロップダウンと検索ボタンの無効化を制御
  useEffect(() => {
    if (isFirstLoadingStart || isShowMoreLoadingStart) {
      changeDropDownDisabled(true);
      changeInputSearchDisabled(true);
      changeBtnSearchDisabled(true);
    } else {
      changeDropDownDisabled(false);
      changeInputSearchDisabled(false);
      if (keyword === '') {
        changeBtnSearchDisabled(true);
      } else {
        changeBtnSearchDisabled(false);
      }
    }
  }, [isFirstLoadingStart, isShowMoreLoadingStart, keyword]);

  return (
    <header className='header' id="top-header">
      <div className="header-left">
        {/* ロゴ */}
        <Logo />

        {/* 検索ツール 選択 */}
        <DropDown
          label="検索ツール"
          name="cmb-tools"
          data={toolOptions}
          value={selectedToolValue}
          setValue={setTool}
          onChange={selected => onCmbToolsChangeHandler(selected)}
          addClass="tool-cmb-box"
          isDropDownDisabled={isDropDownDisabled}
          supplementary={''}
        />

        <div className="search-x">✖</div>

        {/* 勤務地 選択 */}
        <DropDown
          label="勤務地"
          name="cmb-areas"
          data={areaOptionsRef.current}
          value={selectedAreaValue}
          setValue={setArea}
          onChange={selected => setSelectedAreaValue(selected)}
          addClass="area-cmb-box"
          isDropDownDisabled={isDropDownDisabled}
          supplementary={selectedToolSupplementary}
        />

        <div className="search-x">✖</div>

        {/* 検索BOX */}
        <InputSearch
          isInputSearchDisabled={isInputSearchDisabled}
          scrollTop={scrollTop}
          tool = {tool}
          area = {area}
          keyword={keyword}
          setKeyword={setKeyword}
          />

        {/* 送信ボタン */}
        <BtnSearch
          isBtnSearchDisabled={isBtnSearchDisabled}
          scrollTop={scrollTop}
          tool = {tool}
          area = {area}
          keyword = {keyword}
        />

        {/* CSV出力ボタン */}
        {(Object.keys(lists).length !== 0) && (
          (!isFirstLoadingStart && !isShowMoreLoadingStart) && ( <BtnCSV /> )
        )}
      </div>

      <div className="header-right">
        {/* 検討リスト */}
        <BtnHeaderIcon
          title={"検討リスト"} src={iconReview}
          operation={() => dispatch(reviewList())}
        />
        {/* アカウント管理 */}
        <BtnHeaderIcon
          title={"アカウント管理"} src={iconAccount}
          operation={() => dispatch(forAdministrator())}
        />
        {/* ログアウト */}
        <BtnHeaderIcon
          title={"ログアウト"} src={iconLogOut}
          operation={() => dispatch(signOut())}
        />
      </div>
    </header>
  );
});

export default Header;