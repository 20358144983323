import React, { memo } from 'react';
import { BtnLinkDetail } from '../'

const List = memo((props) => {
  const list = props.list;
  return (
    <li className="list">
      <div className="list-title">
        <div className="list-title-text">
          <a href={list.detailUrl} target="_blank" rel="noopener noreferrer">{list.title}</a>
        </div>
          {/* 詳細ボタン */}
        <BtnLinkDetail
          detailUrl={list.detailUrl}
        />
      </div>
      <table border={1} className="list-table" >
        <tbody>
          <tr>
            <th>会社名</th>
            <td><a href={list.companyUrl} target="_blank" rel="noopener noreferrer">{list.companyName}</a></td>
          </tr>
          <tr>
            <th>勤務地</th>
            <td>{list.workLocation}</td>
          </tr>
          <tr>
            <th>年収</th>
            <td>{list.salary}</td>
          </tr>
          <tr>
            <th>掲載期間</th>
            <td>{list.viewingPeriod}</td>
          </tr>
        </tbody>
      </table>
    </li>
  );
});

export default List;