import React from 'react';
// import { Alert, AlertTitle } from '@material-ui/lab';
// import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { getAlertText, getAlertTitle } from '../../../redux/alerts/selectors';
import iconAlert from '../../../assets/img/icon-alert.png';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     margin:'0 auto',
//     width: '90%',
//     // '& > * + *': {
//     //   marginTop: theme.spacing(2),
//     // },
//   },
//   alert: {

//   }
// }));

const AlertBar = (props) => {
  // const classes = useStyles();
  const selector = useSelector(state => state);
  // const alertSeverity = getAlertSeverity(selector);
  const alertTitle = getAlertTitle(selector);
  const alertText = getAlertText(selector);

  return (
    // <div className = {classes.root}>
    //   <Alert severity={alertSeverity}>
    //     <AlertTitle>{alertTitle}</AlertTitle>
    //     {alertText}
    //     {/* <strong>{props.strong}</strong> */}
    //   </Alert>
    // </div>
      <div className="err-alert">
        <div className= "err-alert-header">
          <img src={iconAlert} alt="エラー" /><p>{alertTitle}</p>
        </div>
        <p className= "err-alert-text">{alertText}</p>
      </div>

  );
};

// severity…error、warning、info、success

export default AlertBar;