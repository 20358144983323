import React, { memo } from 'react';
import { Tooltip } from '@material-ui/core';

const BtnHeaderIcon = memo((props)=>{
  return(
    <div className="btn-header-icon">
      <Tooltip title={props.title}>
        <img
          src={props.src} alt={props.title}
          onClick={e => {
            e.preventDefault();
            props.operation();
          }}
        />
      </Tooltip>
    </div>
  );
});

export default BtnHeaderIcon;