import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  button: {
    backgroundColor: "#ff9b21",
    // backgroundColor: "#3f51b5",
    color: "#fff",
    fontSize: 16,
    height: 54,
    marginTop: 30,
    marginBottom: 16,
    width: 250,
    borderRadius:30,
    fontWeight: 800
  }
});

const ButtonDefault = (props) => {
  const classes = useStyles();
  return(
    <Button
      className={classes.button}
      disabled={props.disabled}
      variant="contained"
      onClick={ e => {
        e.preventDefault();
        props.onClick();
      }}
    >{props.label}</Button>
  );
};

export default ButtonDefault;