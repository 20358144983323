/***********************
  [loading] actions.js
 ***********************/

// typeを定義
export const START_FIRST_LOADING = "START_FIRST_LOADING";
export const START_SHOWMORE_LOADING = "START_SHOWMORE_LOADING";
export const STOP_LOADING = "STOP_LOADING";

// ActionCreatorを作成
export const startFirstLoadingAction = (loadingState) => {
  return {
    type: "START_FIRST_LOADING",
    payload: {
      isFirstLoadingStart: true,
      isShowMoreLoadingStart: false
    }
  };
};

export const startShowMoreLoadingAction = (loadingState) => {
  return {
    type: "START_SHOWMORE_LOADING",
    payload: {
      isFirstLoadingStart: false,
      isShowMoreLoadingStart: true
    }
  };
};

export const stopLoadingAction = () => {
  return{
    type: "STOP_LOADING",
    payload: {
      isFirstLoadingStart: false,
      isShowMoreLoadingStart: false
    }
  };
};