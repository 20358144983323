import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { search } from '../../redux/search/operations';
import { getArea, getKeyword, getNextNo, getTool } from '../../redux/search/selectors'

const BtnShowMore = () => {
  const dispatch = useDispatch();
  const selector = useSelector(state => state);
  const tool = getTool(selector);
  const area = getArea(selector);
  const keyword = getKeyword(selector);
  const nextNo = getNextNo(selector);
  return (
    <button
      className="btn-show-more"
      onClick={() => {
        dispatch(search(tool, area, keyword, nextNo, false)) // ------------------------------------------------------------------引数未入力　ここから
      }}
    >
      <ExpandMoreIcon style={{fontSize:"60px"}}/>
    </button>
  );
};

export default BtnShowMore;