import React ,{ memo, useEffect } from 'react';

const DropDown = memo((props) => {
  // console.log('DropDown');
  // const [supplementary , setSupplementary] = useState('');

  useEffect(() => {
    DisabledDropDown(props.name);
  });

  // ドロップダウン無効時のclass（hover時の挙動）変更
  const DisabledDropDown = cmbName =>{
    const cmb = document.getElementById(cmbName);
    props.isDropDownDisabled ? cmb.classList.add('disabled') : cmb.classList.remove('disabled');
  };

  const ChangeValue = e => {
    e.preventDefault();
    props.setValue(e.target.value);
  };

  return (
    <div className={"cmb-box-wrapper " + props.addClass}>
      <label htmlFor={props.name} className="cmb-box_select-label">{props.label}</label>
      <select
        name={props.name}
        id={props.name}
        className="cmb-box"
        required
        disabled={props.isDropDownDisabled}
        // onMouseEnter={() => setSupplementary(supplementary)}
        // onMouseLeave={() => setSupplementary('')}
        // defaultValue={data[0].value} // defaultValue不要（※Select Console Error: Select elements must be either controlled or uncontrolled）
        value={props.value}
        onChange={ e => {
          let eVal = e.target.value
          if(eVal !== undefined){
            props.onChange(eVal);
            ChangeValue(e);
          }
        }}
      >
        {props.data.map((item, index) => {
          return (
            // <optgroup label={'item'}>
              <option value={item.value} key={index}>{item.display}</option>
              // <option value={item.value} key={index} labe={'item'}>{item.display}</option>
            // </optgroup>
          );
        })}
      </select>
      <div className="cmb-box-supplementary">{props.supplementary}</div>
    </div>
  );
});

export default DropDown;