import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import errorImage from '../../assets/img/err.png';
import { ButtonDefault } from '../components';

const PageError = () => {
  const dispatch = useDispatch();

  return(
    <div className="section-container">
      <div className="section-contents">
        <h1><img src={errorImage} alt="Error" /></h1>
        <h2>システムエラーが発生しました。</h2>
        <p>ご不便をおかけし大変申し訳ございません。</p>
        <p>しばらくお時間をおいてから、再度ご利用ください。</p>
        <p>大変お手数ですが、システム管理者までお問い合わせください。</p>
        <div className="center">
          <ButtonDefault
            label={"戻る"}
            disabled={false}
            onClick={() => dispatch(push('/login'))}
          />
        </div>
      </div>
    </div>
  );
};

export default PageError;