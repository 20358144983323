import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Header, SearchList, BtnReturnTop, Loading, NoResults, ShowMore} from '../components';
import { getIsFirstLoadingStart } from '../../redux/loading/selectors';
import { getIsErrorSearchOpen, getIsSearchListOpen, getIsNoResultsOpen, getNextNo} from '../../redux/search/selectors';
import { getIsSignedIn } from '../../redux/users/selectors';
import { listenAuthState } from '../../redux/users/operations';

const Top = () => {
  const dispatch = useDispatch();
  const selector = useSelector(state => state);
  const isSignedIn = getIsSignedIn(selector);
  const isFirstLoadingStart = getIsFirstLoadingStart(selector);
  const nextNo = getNextNo(selector);
  const isSearchListOpen = getIsSearchListOpen(selector);
  const isErrorSearchOpen = getIsErrorSearchOpen(selector);
  const isNoResultsOpen = getIsNoResultsOpen(selector);

  // ログイン後のブラウザバッグ制御
  useEffect(() => {
    window.addEventListener('popstate', e => {
      e.preventDefault();
      dispatch(listenAuthState(isSignedIn));
    });
  },[dispatch, isSignedIn]);

  return (
    <div className="top-container">
      <Header />
      <main>
        { isFirstLoadingStart ? (
          <div className="loading-wrapper">
            <div className="init-loading">
              <Loading />
            </div>
          </div>
        ) : (
          <>
          {isSearchListOpen ? (
            <>
              <div className="search-result-wrapper">
                {isNoResultsOpen ?  <NoResults /> : <SearchList />}
              </div>
              {/* show more */}
              {(nextNo !== 0) && ( <ShowMore /> )}
            </>
          ) : (
            <div className="search-result-wrapper">
              {/* TODO ここは、NoResultsではなく、エラー画面をもってくる */}
              {isErrorSearchOpen && ( <NoResults /> )}
            </div>
          )}

          {/* Topへ戻るボタン */}
          <BtnReturnTop />
          </>
        )}
      </main>
      {/* <Footer /> */}
    </div>
  );
};

export default Top;
