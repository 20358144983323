/*************************
  [search] selections.js
 *************************/

import { createSelector } from "reselect";

const searchSelector = state => state.search;

// stateを取得する関数
export const getLists = createSelector(
  [searchSelector],
  state => state.lists
);

export const getTool = createSelector(
  [searchSelector],
  state => state.tool
);

export const getArea = createSelector(
  [searchSelector],
  state => state.area
);

export const getKeyword = createSelector(
  [searchSelector],
  state => state.keyword
);

export const getTotalCount = createSelector(
  [searchSelector],
  state => state.totalCount
);

export const getNextNo = createSelector(
  [searchSelector],
  state => state.nextNo
);

export const getIsSearchListOpen = createSelector(
  [searchSelector],
  state => state.isSearchListOpen
);

export const getIsErrorSearchOpen = createSelector(
  [searchSelector],
  state => state.isErrorSearchOpen
);

export const getIsNoResultsOpen = createSelector(
  [searchSelector],
  state => state.isNoResultsOpen
);

