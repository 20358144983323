/************************
  [alerts] reducers.js
 ************************/

import * as Actions from './actions';
import initialState from '../store/initialState';

// state状態変化させる関数を作成
export const AlertsReducer = (state = initialState.alerts, action) => {
  switch (action.type){
    case Actions.ALERT_OPEN:
      return {
        ...state,
        ...action.payload
      };

    case Actions.ALERT_CLOSE:
      return {
        ...action.payload
      };

    default:
      return state;

  }
};