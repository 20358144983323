/*************************
  [alerts] selections.js
 *************************/

import { createSelector } from "reselect";

const alertsSelector = state => state.alerts;

// stateを取得する関数
export const getIsAlertBarOpen = createSelector(
  [alertsSelector],
  state => state.isAlertBarOpen
);

// export const getAlertSeverity = createSelector(
//   [alertsSelector],
//   state => state.alertSeverity
// );

export const getAlertTitle = createSelector(
  [alertsSelector],
  state => state.alertTitle
);

export const getAlertText = createSelector(
  [alertsSelector],
  state => state.alertText
);