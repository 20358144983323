import { createStore, combineReducers,  applyMiddleware, compose } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
// 各reducerをimport
import { UsersReducer } from '../users/reducers';
import { AlertsReducer } from '../alerts/reducers';
import { SearchReducer } from '../search/reducers';
import { LoadingReducer } from '../loading/reducers';

// Redux DevTools(Chrome)設定
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// store生成用関数
export const generateStore = (history) => {
  return createStore(
    combineReducers({
      users: UsersReducer,
      alerts: AlertsReducer,
      search: SearchReducer,
      loading: LoadingReducer,

      router: connectRouter(history)
    }),
    // applyMiddleware(
    composeEnhancers(applyMiddleware(
      routerMiddleware(history),
      thunk
    ))
  );
};