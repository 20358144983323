import React, { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
// import SearchIcon from '@material-ui/icons/Search';
import { search } from '../../../redux/search/operations';
import imgSVGPath from '../../../data/imgSVGPath';


const BtnSearch = memo((props) => {
  // console.log('btnSearch');
  const dispatch = useDispatch();

  useEffect(() => {
    // 検索ボタン活性におけるclass（hover時の挙動）切り替え
    const btnSearch = document.getElementById('btnSearch');
    if (!props.isBtnSearchDisabled) {
      btnSearch.classList.add('enabled');
    } else {
      btnSearch.classList.remove('enabled');
    }
  },[props.isBtnSearchDisabled]);

  return (
    <button
      className='btn-search'
      id="btnSearch"
      aria-label="search"
      disabled={props.isBtnSearchDisabled}
      onClick={(e) => {
        e.preventDefault();
        dispatch(search(props.tool, props.area, props.keyword, 1, true))
        props.scrollTop();
      }}
    >
      <svg className="icon-search" viewBox="0 0 512 512">
        <path d={imgSVGPath.iconSearchSVGPath}></path>
      </svg>

      {/* <SearchIcon className="icon-search"/> */}

    </button>
  );
});

export default BtnSearch;