import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import noResultsImage from '../../assets/img/noResults.png';
import { TotalCount } from '.';
import { getTotalCount } from '../../redux/search/selectors';
// import { getKeyword } from '../../redux/search/selectors';

const NoResults = memo(() => {
  const selector = useSelector(state => state);
  const totalCount = getTotalCount(selector);
  // const keyword = getKeyword(selector);

  return (
    <div className="no-results-wrapper">
      {/* 総件数 */}
      <TotalCount
        totalCount={totalCount}
      />

      <div className="no-results-text">
        <img src={noResultsImage} alt="No Results"/>
        {/* <h2>『{keyword}』の検索結果</h2> */}
        <h3>検索条件に該当する求人情報が見つかりません。</h3>
        <p>条件を変更して、もう一度検索してください。</p>
      </div>
    </div>
  );
});

export default NoResults;