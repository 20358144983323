/*************************
  [loading] operations.js
 *************************/

import * as Actions from './actions';

// Actionsを呼び出す関数

// ▼ 初回ローディング START
export const startFirstLoading = () => {
  return async (dispatch) => {
    dispatch(Actions.startFirstLoadingAction());
  };
}

// ▼ ShowMoreローディング START
export const startShowMoreLoading = () => {
  return async (dispatch) => {
    dispatch(Actions.startShowMoreLoadingAction());
  };
}

// ▼ ローディング 終了
export const stopLoading = () => {
  return async (dispatch) => {
    dispatch(Actions.stopLoadingAction());
  };
}


