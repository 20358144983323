/***********************
  [alerts] actions.js
 ***********************/

// typeを定義
export const ALERT_OPEN = "ALERT_OPEN";
export const ALERT_CLOSE = "ALERT_CLOSE";

// ActionCreatorを作成
export const alertOpenAction = (alertState) => {
  return {
    type: "ALERT_OPEN",
    payload: {
      isAlertBarOpen: true,
      // alertSeverity: alertState.alertSeverity,
      alertTitle: alertState.alertTitle,
      alertText: alertState.alertText,
    }
  };
};

export const alertCloseAction = () => {
  return{
    type: "ALERT_CLOSE",
    payload: {
      isAlertBarOpen: false,
      // alertSeverity: '',
      alertTitle: '',
      alertText: '',
    }
  };
};