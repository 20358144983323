import React from 'react';
import { Route, Switch } from "react-router";
import { Top, Login, PageError, Page404 } from './views/pages';
import Auth from './Auth';

const Router = () => {
  return(
    <Switch>
      {/* 認証不要 */}
      <Route exact path="/login" component={Login} />
      <Route exact path="/err" component={PageError} />

      {/* 認証必要 */}
      <Auth>
        <Switch>
          <Route exact path="(/)?" component={Top} />
          {/* 404 NOT FOUND */}
          <Route component={Page404} />
        </Switch>
      </Auth>

    </Switch>
  );
};

export default Router;